/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import KoopsomInformatie from "../../components/Addtocartwordpress/Koopsominformatie";
import KoopsommenOverzicht from "../../components/Addtocartwordpress/KoopsommenOverzicht";

const PostSummary = ({ result, detail }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (!result) return;
    try {
      const cheerio = require("cheerio");
      let $ = cheerio.load(result);
      let price, pricedetail, date;

      $("div.page-summary__amount").each((i, element) => {
        price = element.children[0].data;
      });
      $("div.page-summary__text-wrapper--mobile-fade").each((i, element) => {
        pricedetail = $(element)
          .html()
          .replace("https://www.kadasterdata.nl", "")
          .replace("https://www.kadasterdata.nl", "");
      });
      $("div.page-summary__date").each((i, element) => {
        date = $(element).html();
      });
      setData({ price, pricedetail, date });
    } catch (error) {
      console.log(error);
    }
  }, [result]);
  const cadastralData = [
    {
      text: "Woningwaarde rapport",
      url: "#",
    },
    {
      text: "Koopsominformatie",
      url: "#",
    },
    {
      text: "Koopsom + WOZ-waarde",
      url: "#",
    },
  ];

  const homeForSale = [
    {
      text: "Checklist bezichtiging",
      url: "#",
    },
    {
      text: "Persoonlijk stappenplan huis kopen",
      url: "#",
    },
    {
      text: "Slim bieden in 3 stappen",
      url: "#",
    },
  ];
  return (
    <StyledContainer>
      <PricePanel className="plaatnasam-339">
        <Box className="plaatnaam-339">
          <Box className="iiir8484">
            <Box fontSize={"22px"} fontWeight={600} mb={"20px"}>
              Woning samenvatting
            </Box>
            <Box>
              Woningwaarde indicatie{" "}
              <StyledLink to={"#"}>
                Actuele woningwaarde opvragen (gratis)
              </StyledLink>
            </Box>
            <Box
              color={"#607d8b"}
              fontSize={"1.5rem"}
              fontWeight={"600"}
              mt={"10px"}
            >
              {data?.price}
            </Box>
            <Box
              dangerouslySetInnerHTML={{ __html: data?.date }}
              display={"flex"}
              fontSize={"14px"}
              mb={"20px"}
            />
            <Box dangerouslySetInnerHTML={{ __html: data?.pricedetail }} />
          </Box>
          <Box
          className="margined-3303"
            bgcolor={"#ffffff"}
            padding={"2.25rem 2.5rem 2.75rem"}
            borderRadius={"5px"}
            height={"fit-content"}
            border={"1px solid #ddd"}
          >
            <CadastralData>
              <Box
                fontSize={"18px"}
                fontWeight={600}
                pb={"20px"}
              >
                Kadastrale gegevens
              </Box>
              <Box>
                    <Box>
                      <Box display={"flex"} alignitems={"center"}>
                        <img src={"/icons/cadastral.svg"} />
                       <KoopsomInformatie />
                      </Box>
                      <FaChevronRight className="chevronnnn" />
                    </Box>
                    <Box>
                      <Box display={"flex"} alignitems={"center"}>
                        <img src={"/icons/cadastral.svg"} />
                       <KoopsommenOverzicht />
                      </Box>
                      <FaChevronRight className="chevronnnn" />
                    </Box>

              </Box>
            </CadastralData>

            <CadastralData mt={"3.75rem"}>
              <Box
                fontSize={"18px"}
                fontWeight={600}
                pb={"20px"}
              >
                Op zoek naar een koopwoning?
              </Box>
              <Box>
                {homeForSale.map((data, i) => {
                  return (
                    <Box>
                      <Box display={"flex"} alignitems={"center"}>
                        <Box>{data.text}</Box>
                      </Box>
                      <FaChevronRight className="chevronnnn" />
                    </Box>
                  );
                })}
              </Box>
            </CadastralData>
          </Box>
        </Box>
      </PricePanel>
    </StyledContainer>
  );
};

const CadastralData = styled(Box)`
  > div > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.125rem 0;
    border-bottom: 2px solid #e8e8e8;
    cursor: pointer;
    :hover {
      > div > div {
        text-decoration: underline;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #607d8b;
  text-decoration: none;
  :hover {
    color: #607d8b;
    text-decoration: underline;
  }
`;

const PricePanel = styled(Box)`
  background: #ffffff;
  padding: 3.125rem 0;
  display: flex;
  > div {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-left: 0%;
    padding-right: 0%;
  }
`;

const StyledContainer = styled(Box)``;

export default PostSummary;
