import { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { Box } from "@mui/material";
import Topbar from "./components/Topbar";
import Migrate from "./pages/Base";
import HousingMarket from "./pages/HousingMarket";
import StreetHome from "./pages/StreetHome";
import Provincies from "./pages/Provincies";
import Property from "./pages/Property";
import KoopWoningen from "./pages/KoopWoningen";

import "./App.css";
import Notification from "./components/Notification";
import CartItemsContainer from "./components/cart/cart-items-container";  
function App() {
  const [notification, setNotification] = useState(null);

  return (
    <BrowserRouter>
      <Box position="relative">
        <Topbar />
        <Routes>
          <Route path="/" element={<Navigate replace to="/huizendata" />} />
          <Route path="/:id" element={<HousingMarket />} />
          <Route path="/:id/:id" element={<StreetHome />} />
          <Route path="/cart" element={<CartItemsContainer />} />
          <Route path="/:id/:id/:id" element={<Property />} />
          <Route path="/provincies/:id" element={<Provincies />} />
          <Route path="/koopwoningen/:id" element={<KoopWoningen />} />  
          <Route exact path="/huizendata" element={<Migrate setNotification={setNotification} />
          }
          
          />
        </Routes>
        
        {/* <Footer /> */}
      </Box>
      <Notification data={notification} />
    </BrowserRouter>
  );
}

export default App;
