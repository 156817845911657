/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import MarketValue from "./MarketValue";
import StreetTable from "./StreetTable";
import HomeList from "./HomeList";
import PostSummary from "./PostSummary";
import BouncingDotsLoader from "../../components/BouncingDotsLoader";

const HousingMarket = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [pending, setPending] = useState(false);
  const [result, setResult] = useState(null);
  const [url, setUrl] = useState(`https://www.kadasterdata.nl/${id}`);

  async function fetchData() {
    setPending(true);
    try {
      const _result = await axios.post(
        "https://kadaster-backend.vercel.app/api/htmlController/getHTML",
        { url }
      );
      setResult(_result.data.result);
      const cheerio = require("cheerio");
      let $ = cheerio.load(_result.data.result);
      let province, place, price, pricedetail, marketvalue;
      $("li.breadcrumb__item").each((i, element) => {
        if (i === 1)
          province = {
            title: element.children[1].attribs.title,
            url: element.children[1].attribs.href,
          };
        if (i === 2) place = element.children[1].children[0].data;
      });
      $("div.page-summary__amount").each((i, element) => {
        price = element.children[0].data;
      });
      $("div.page-summary__amount").each((i, element) => {
        price = element.children[0].data;
      });
      $("div.page-summary__text-wrapper--mobile-fade").each((i, element) => {
        pricedetail = $(element).html();
      });
      $("div.properties").each((i, element) => {
        if (i === 0) marketvalue = $(element).html();
      });

      setData({ province, place, price, pricedetail, marketvalue });
    } catch (error) {
      console.log(error);
    }
    setPending(false);
  }
  useEffect(() => {
    fetchData();
  }, [url]);

  return (
    <StyledContainer width={"100%"}>
      {!data ? (
          <BouncingDotsLoader />

      ) : (
        <>
          <PostSummary data={data} />
          <MarketValue data={data.marketvalue} />
          <StreetTable
            result={result}
            pending={pending}
            setPending={setPending}
            setUrl={setUrl}
            id={id}
          />
          <HomeList
            result={result}
            place={data.place}
            province={data.province}
          />
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;
`;

export default HousingMarket;
