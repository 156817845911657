import * as React from 'react';
import Button from '@mui/material/Button';
 

export default function KadastraleBtn1() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="fade-button"
        onClick={handleClick}
      >
        Producten
      </Button>
    </div>
  );
}
