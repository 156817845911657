/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import HomeCards from "./HomeCards";
import BouncingDotsLoader from "../../components/BouncingDotsLoader";


const KoopWoningen = () => {
  const hrefList = window.location.href.split("/");
  const href =
    hrefList[hrefList.length - 2] + "/" + hrefList[hrefList.length - 1];
  const [url, setUrl] = useState(`https://www.kadasterdata.nl/${href}`);
  const [result, setResult] = useState(null);
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);

  async function fetchData() {
    setPending(true);
    try {
      const _result = await axios.post(
        "https://kadaster-backend.vercel.app/api/htmlController/getHTML",
        { url }
      );
      setResult(_result.data.result);
      const cheerio = require("cheerio");
      let $ = cheerio.load(_result.data.result);
      let links = [],
        title,
        hrefs = [];
      $("li.breadcrumb__item").each((i, element) => {
        links.push(element.children[1].children[0].data);
        hrefs.push(
          i !== 3
            ? element.children[1].attribs.href.replace(
                "https://www.kadasterdata.nl",
                ""
              )
            : ""
        );
      });
      $("h1.page-header__title").each((i, element) => {
        title = element.children[0].data;
      });
      setData({ links, title, hrefs });
    } catch (error) {
      console.log(error);
    }
    setPending(false);
  }
  useEffect(() => {
    fetchData();
  }, [url]);
  return (
    <StyledContainer width={"100%"}>
      {!data ? (
          <BouncingDotsLoader />

      ) : (
        <>
          <HomeCards
            result={result}
            detail={data?.links}
            setUrl={setUrl}
            pending={pending}
            name={hrefList[hrefList.length - 1]}
          />
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;
`;

export default KoopWoningen;
