import React from "react";
import styled from "styled-components";

const Button = ({
  width,
  height,
  type,
  fontSize = "16px",
  children,
  disabled,
  onClick,
  color = "#263238",
  style,
  active,
}) => {
  return (
    <>
      {type === "search" ? (
        <SearchButton
          width={width}
          height={height}
          type={type}
          fontSize={fontSize}
          disabled={disabled}
          onClick={onClick}
          style={style}
          color={color}
        >
          {children}
        </SearchButton>
      ) : type === "subscribe" ? (
        <SubscribeButton
          width={width}
          height={height}
          type={type}
          fontSize={fontSize}
          disabled={disabled}
          onClick={onClick}
          style={style}
          color={color}
        >
          {children}
        </SubscribeButton>
      ) : type === "connect" ? (
        <ConnectButton
          width={width}
          height={height}
          type={type}
          fontSize={fontSize}
          disabled={disabled}
          onClick={onClick}
          style={style}
          color={color}
        >
          {children}
        </ConnectButton>
      ) : type === "max" ? (
        <MaxButton
          width={width}
          height={height}
          type={type}
          fontSize={fontSize}
          disabled={disabled}
          onClick={onClick}
          style={style}
          color={color}
        >
          {children}
        </MaxButton>
      ) : type === "primary" ? (
        <PrimaryButton
          width={width}
          height={height}
          type={type}
          fontSize={fontSize}
          disabled={disabled}
          onClick={onClick}
          style={style}
          color={color}
          active={active}
        >
          {children}
        </PrimaryButton>
      ) : (
        ""
      )}
    </>
  );
};

const BaseButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "THICCCBOISemiBold";
  font-size: ${({ fontSize }) => fontSize};
  font-weight: bold;
  min-width: ${({ width }) => width};
  min-height: ${({ height }) => height};
  max-width: ${({ width }) => width};
  max-height: ${({ height }) => height};
  cursor: pointer;
  transition: all 0.3s;
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SearchButton = styled(BaseButton)`
  color: white;
  background-color: #607d8b;
  border-radius: 5px;
  > div {
    justify-content: space-between;
    > svg {
      font-size: 22px;
    }
    width: 100%;
    max-width: 120px;
  }
  padding-left: 20px;
  justify-content: unset;
  font-size: 18px;
  :hover {
    opacity: 0.75;
  }
`;

const SubscribeButton = styled(BaseButton)`
  background: white;
  border-radius: 100px;
  font-size: 18px;
  color: black;
  :hover {
    transform: scale(1.05, 1.05);
  }
`;

const ConnectButton = styled(BaseButton)`
  background: #251026;
  border-radius: 100px;
  box-shadow: 0px 3px 6px rgab(0, 0, 0, 0.35);
  font-size: 18px;
  color: #fedbdc;
  :hover {
    transform: translate(0, -2px);
    box-shadow: 0px 2px 5px #3a444a;
  }
`;

const MaxButton = styled(BaseButton)`
  background: #414052;
  border-radius: 50px;
  box-shadow: 0px 0px 6px rgab(0, 0, 0, 0.15);
  font-size: 20px;
  color: white;
  font-family: "THICCCBOI";
  border: 1px solid transparent;
  :hover {
    background: white;

    border: 1px solid #414052;
    color: #414052;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const PrimaryButton = styled(BaseButton)`
  background: transparent;
  color: ${({ active }) => (active ? "white" : "#607d8b")};
  transition: all 0.3s ease-in-out;
  border: 2px solid #607d8b;
  border-radius: 5px;
  padding: 0.375rem 1.5rem 0.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  background: ${({ active }) => (active ? "#607d8b" : "transparent")};
  :hover {
    background: #607d8b;
    color: white;
  }
`;

export default Button;
