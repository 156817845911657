/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import ProductsGrid from "../../components/ProductsGrid";
import ForwardsUrl from "../../components/forward";
import BasicTabs from "../../components/Product-tab";

const Charateristics = ({ result, detail }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!result) return;
    const cheerio = require("cheerio");
    let $ = cheerio.load(result);
    let temp = [];
    $("div.details__col").each((i, element) => {
      temp.push($(element).html());
    });
    setData(temp);
  }, [result]);
  return (
    <StyledContainer>
      <Box>



<BasicTabs />
        
        <Box fontSize={"22px"} fontWeight={600}>
          Kenmerken
        </Box>
        <Box className="isksu3dnd88757ns" display={"flex"} flexWrap={"wrap"}>
          {data.map((data, i) => {
            return <Item className="wiisisdh474hj" dangerouslySetInnerHTML={{ __html: data }} />;
          })}
        </Box>
      </Box>
    </StyledContainer>
  );
};

const Item = styled(Box)`
  > div {
    display: flex;
    align-items: center;
    padding: 1rem 2.25rem;
    border: 2px solid #ddd;
    background-color: #ffffff !important;

    border-radius: 5px;
    height: 100%;
  }
  padding: 0;
  margin-top:0;
  width: 50%;
  .details__card-icon {
    width: 80px;
  }
  .details__card-value {
    font-weight: 600;
    margin-left: 1rem;
  }
  .details__card-link {
    display: none;
  }
`;

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;
  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 0%;
    padding-right: 0%;
  }
  background-color: #ffffff;
`;

export default Charateristics;
