/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box, Button } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import ProductsGrid from "../../components/ProductsGrid";

const Populate = ({ result }) => {
  const [data] = useState([
    {
      url: "/images/populate/1.jfif",
      title: "Amsterdam",
      detail: "Woningen te koop",
      value: "1.428",
      link: "/koopwoningen/amsterdam",
    },
    {
      url: "/images/populate/2.jfif",
      title: "Rotterdam",
      detail: "Woningen te koop",
      value: "1.065",
      link: "/koopwoningen/rotterdam",
    },
    {
      url: "/images/populate/3.jfif",
      title: "Den Haag",
      detail: "Woningen te koop",
      value: "1.221",
      link: "/koopwoningen/den-haag",
    },
    {
      url: "/images/populate/utrecht.jpg",
      title: "Utrecht",
      detail: "Woningen te koop",
      value: "563",
      link: "/koopwoningen/utrecht",
    },
    {
      url: "/images/populate/eindhoven.jpg",
      title: "Eindhoven ",
      detail: "Woningen te koop",
      value: "410",
      link: "/koopwoningen/Eindhoven",
    },
    {
      url: "/images/populate/groningen.jpg",
      title: "Groningen ",
      detail: "Woningen te koop",
      value: "408",
      link: "/koopwoningen/Groningen",
    },
    {
      url: "/images/populate/tilburg.jpg",
      title: "Tilburg ",
      detail: "Woningen te koop",
      value: "373",
      link: "/koopwoningen/Tilburg",
    },
    {
      url: "/images/populate/almere.jpg",
      title: "Almere ",
      detail: "Woningen te koop",
      value: "373",
      link: "/koopwoningen/Almere",
    },
  ]);

  return (
    <StyledContainer className="whitebg21">
      <ProductsGrid />
      <Box
        display={"flex"}
        className="jshf47hfnf"
        justifycontent={"space-between"}
        alignItems={"start"}
        mb={"20px"}
        mt={"40px"}
      >
<p className="home-title mobile-bold left-txt tx0s2-s"><strong>Woningen te koop in populaire steden</strong><span className="text-span">.</span></p>   
      </Box>
      <ImageBox className="jsjdjsjdsjjs8377777">
        {data.map((data, i) => {
          return (
            <Link to={data.link} key={i}>
              <Box>
                <img className="img-s20sdjss-sss" src={data.url} />
              </Box>
              <Box className="div-sbdsl2js8sjs">
                <Box>{data.title}</Box>
                <div className="cens-2sds">
                <Box className="value-s22-2s">{data.value}</Box>
                <Box>{data.detail}</Box>
                </div>
              </Box>
            </Link>
          );
        })}
      </ImageBox>
    </StyledContainer>
  );
};

const ImageBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
  place-content: center;
  > a {
    text-decoration: none;

    background: #fff;
    border-radius: 10px;
    color: #263238 !important;
    overflow: hidden !important;
    height: 100%;
    > div > img {
      transition: all 0.7s ease-in-out;
    }
    > div:nth-child(1) {
      width: 280px;
      overflow: hidden;
    }
    > div:nth-child(2) {
      border-radius: 0 0 10px 10px;
      border: 0.125rem solid #dcdbd5;
      border-top: none;
      height: 40%;
      padding: 0 1.5rem 1rem;
      padding-bottom: 1rem;
      > div:nth-child(1) {
        padding: 1rem 0 0.75rem;
        font-size: 1.125rem;
        font-weight: 600;
      }
      > div:nth-child(3) {
        font-weight: 600;
      }
    }
    :hover {
      > div > img {
        transform: scale(1.1);
      }
    }
  }
`;

const StyledContainer = styled(Box)`
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
  margin: 0 auto;
`;

export default Populate;
