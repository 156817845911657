import { useNavigate, useParams } from 'react-router-dom';

function ForwardsUrl() {
  const navigate = useNavigate();

  const hrefList = window.location.href.split("/");
    const href = hrefList[hrefList.length - 3] + "/" + hrefList[hrefList.length - 2] + "+" + hrefList[hrefList.length - 1];;

  const idString = href.replace("/", "+");
  const wordpressUrl = `https://www.kadasterzoom.nl/afrekenen/?add-to-cart=231348&billing_first_name=${idString}`;

  const handleClick = () => {
    window.open(wordpressUrl, '_blank');
  };

  return (
    <div>
      <button style={{ backgroundColor: 'green', padding: '10px 15px', borderRadius: '5px', color: 'white' }} onClick={handleClick} target="_blank">Plaats in winkelwagen</button>
    </div>
  );
}
export default ForwardsUrl;