/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { BiSearch } from "react-icons/bi";
import { Divider } from "@material-ui/core";


const HeroSection = () => {
  const [criteria, setCriteria] = useState("");
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false);

  const dialog = useRef();
  useEffect(() => {
    document.addEventListener("mouseup", function (event) {
      if (dialog && dialog.current && !dialog.current.contains(event.target)) {
        setData(0);
      }
    });
  }, []);

  async function fetchData() {
    setPending(true);
    try {
      console.log(criteria);
      const result = await axios.post(
        "https://kadaster-backend.vercel.app/api/htmlController/getHeroSection",
        { url: "https://www.kadasterdata.nl/api-hd/autocomplete", criteria }
      );
      console.log(result.data.result);
      setData([
        ...(result.data.result.geolevels
          ? result.data.result.geolevels.street
          : []),
        ...result.data.result.properties,
      ]);
    } catch (error) {
      console.log(error);
      setData([]);
    }
    setPending(false);
  }
  return (
    <div className="overlsfashsdklasldka">

      <div className="body">
        <div className="div-block header-2">
          <div className="headerimagecontainer">
            <div className="headerimageborder">
              <div className="headerimage"></div>
            </div>
          </div>
          <div className="headercontent">
            <div className="headertitle">
              <h1 className="heading">
                Huizen check doe je hier<span className="colored-a">.</span>
              </h1>
              <h2 className="heading-2">
                Ontdek zo alle woningwaardes in het grootste aanbod
              </h2>
            </div>
            <div className="headersearch">
              <p className="paragraph">
                Bekijk handige informatie per adres in één overzicht
              </p>

              <Box width={"100%"}>
                <div mt={"40px"}>

<div className="search w-form" display={"flex"} mt={"10px"}>
  <div className="inputed-200">
    <div
      display={"flex"}
      alignitems={"center"}
      style={{ cursor: "pointer" }}
      borderRight={"1px solid #dcdbd5"}
      padding={"0 1rem"}
    ></div>
    <input
      type={"text"}
      placeholder={"Zoek op plaats of adres"}
      value={criteria}
      onChange={(e) => setCriteria(e.target.value)}
      onKeyPress={(e) => {
       
          fetchData();
      }}
    />
    {data && data.length ? (
      <div className="div-search-2" ref={dialog}>
        <Box fontSize={"1.25rem"} fontWeight={"600"} paddingBottom={"1rem"}>
          Straat naam
        </Box>
        <Divider className="dividerwhite" />
        <Box className="flex-resulsts-2">
          {data.map((data, i) => {
            return (
              <Link
                className="coloredlinkss"
                key={i}
                to={`${data.url.replace(
                  "https://www.kadasterdata.nl",
                  ""
                )}`}
              >
                {data.title}
              </Link>
            );
          })}
        </Box>
      </div>
    ) : (
      ""
    )}
  </div> 
  <Button
    className="search-button BTNSTYLE"
    type={"search"}
    width={"150px"}
    height={"50px"}
    onClick={() => fetchData()}
  >
    {!pending ? (
      <Box className="buttonSRCH" display={"flex"} alignitems={"center"}>
        <BiSearch />
        Zoeken
      </Box>
    ) : (
      <Box
        className="loader-22202"
        maxWidth={"100%!important"}
        display={"flex"}
        justifycontent={"center!important"}
        pr={"20px"}
      >
        <Oval
          height={25}
          width={25}
          MarginLeft="auto"
          color="#adadad"
          wrapperstyle={{}}
          wrapperclassname=""
          visible={true}
          aria-label="oval-loading"
          secondarycolor="#dfdfdf"
          strokeWidth={5}
          strokewidthsecondary={3}
        />
      </Box>
    )}
  </Button>
</div>


                  <div className="sub-promo">
                    Top 4 woningmarkten:{" "}
                    <Link className="bold-text-6" to={"/amsterdam"}>
                      Amsterdam
                    </Link>
                    ,{" "}
                    <Link className="bold-text-6" to={"/rotterdam"}>
                      Rotterdam
                    </Link>
                    ,{" "}
                    <Link className="bold-text-6" to={"/den-haag"}>
                      Den Haag
                    </Link>
                    ,{" "}
                    <Link className="bold-text-6" to={"/utrecht"}>
                      Utrecht
                    </Link>
                    ,{" "}
                    <Link className="bold-text-6" to={"/groningen"}>
                      Groningen
                    </Link>
                    .
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
        <script
          src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=637351ac27b29126a2b07fc1"
          type="text/javascript"
          integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
          crossorigin="anonymous"
        ></script>
        <script src="js/webflow.js" type="text/javascript"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script>
      </div>
    </div>
  );
};

export default HeroSection;
