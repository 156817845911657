/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";

const HomeList = ({ result, detail }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!result) return;
    const cheerio = require("cheerio");
    let $ = cheerio.load(result);
    let _maxNum = 0;
    let temp = Array(100)
      .fill(0)
      .map((row) => new Array(0).fill(""));
    $("li.grid-view__list-item").each((i, element) => {
      const text = element.children[1].children[1].data;
      const href = element.children[1].attribs.href.replace(
        "https://www.kadasterdata.nl",
        ""
      );
      const list = text.split(" ");
      const num = list[list.length - 1][0];
      temp[num].push({ text, href });
      _maxNum = num;
    });
    setData(temp);
    temp.length = _maxNum + 1;
  }, [result]);
  return (
    <StyledContainer>
      <Box>
        <Box fontSize={"22px"} fontWeight={600}>
         
        </Box>
        {data.map((data, i) => {
          if (!data.length) return "";
          return (
            <>
              <CircleBox>{i}</CircleBox>
              <Box key={i} display={"flex"} flexWrap={"wrap"}>
                {data.map((data, i) => {
                  return (
                    <StyledLink to={data.href} key={i}>
                      {data.text}
                    </StyledLink>
                  );
                })}
              </Box>
            </>
          );
        })}
      </Box>
    </StyledContainer>
  );
};

const CircleBox = styled(Box)`
  border-bottom: 2px solid #e8e8e8;
  width: 100%;
  margin-bottom: 0.75rem;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #607d8b;
  font-weight: 600;
  color: #fff !important;
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  :hover {
    color: #ffffff;
    text-decoration: underline;
  }
  height: 72px;
  display: flex;
  align-items: center;
  width: 300px;
  border-bottom: 2px solid #e8e8e8;
`;

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;

  > div {
    max-width: 1200px;
    padding-left: 0%;
    padding-right: 0%;
    margin: 0 auto;
    background-color: #fff;
    border: 2px solid #dcdbd5;
    border-radius: 5px;
    padding: 2rem 2.75rem;
    height: fit-content;
    width: 100%;
  }
  background-color: #fff;
`;

export default HomeList;
