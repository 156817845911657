/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ForwardsUrl from '../../components/forward';

const PostSummary = ({ result, detail }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (!result) return;
    try {
      const cheerio = require("cheerio");
      let $ = cheerio.load(result);
      let price, pricedetail;

      $("div.page-summary__amount").each((i, element) => {
        price = element.children[0].data;
      });
      $("div.page-summary__text-wrapper--mobile-fade").each((i, element) => {
        pricedetail = $(element).html();
      });
      setData({ price, pricedetail });
    } catch (error) {
      console.log(error);
    }
  }, [result]);
  const cadastralData = [
    {
      text: "Woningwaarde rapport",
      url: "#",
    },
    {
      text: "Koopsommenoverzicht",
      url: "#",
    },
    {
      text: "Koopsom + WOZ-waarde",
      url: "#",
    },
  ];

  const homeForSale = [
    {
      text: "Checklist bezichtiging",
      url: "#",
    },
    {
      text: "Persoonlijk stappenplan huis kopen",
      url: "#",
    },
    {
      text: "Slim bieden in 3 stappen",
      url: "#",
    },
  ];

  
  return (
    <StyledContainer>
      <PricePanel className="plaatnasam-339">
        <Box className="plaatnaam-339">
          <Box className="iiir8484">
            <Box fontSize={"22px"} fontWeight={600} mb={"20px"}>
              Samenvatting
            </Box>
            <Box mb={"15px"}>
              <Box fontWeight={600}>Straatnaam</Box>
              <Box>{detail ? detail[3] : ""}</Box>
            </Box>
            <Box mb={"15px"}>
              <Box fontWeight={600}>Provincie</Box>
              <StyledLink to={"#"}>{detail ? detail[1] : ""}</StyledLink>
            </Box>
            <Box mb={"15px"}>
              <Box fontWeight={600}>Plaats</Box>
              <StyledLink to={"#"}>{detail ? detail[2] : ""}</StyledLink>
            </Box>
            <Box fontWeight={600}>Gemiddelde woningwaarde</Box>
            <Box
              color={"#607d8b"}
              fontSize={"1.5rem"}
              fontWeight={"600"}
              mb={"20px"}
            >
              {data?.price}
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: data?.pricedetail }} />
          </Box>
          <Box
          className="margined-3303"
            bgcolor={"#ffffff"}
            padding={"2.25rem 2.5rem 2.75rem"}
            borderRadius={"5px"}
            height={"fit-content"}
            border={"1px solid #ddd"}
          >

            <CadastralData>
              <Box
                fontSize={"18px"}
                fontWeight={600}
                pb={"20px"}
              >
                Kadastrale gegevens
              </Box>
              <Box>
                {cadastralData.map((data, i) => {
                  return (
                    <Box>
                      <Box display={"flex"} alignitems={"center"}>
                        <img src={"/icons/cadastral.svg"} />
                        <Box ml={"16px"}>{data.text}</Box>
                      </Box>
                      <FaChevronRight className="chevronnnn" />
                    </Box>
                  );
                })}
              </Box>
            </CadastralData>

            <CadastralData mt={"3.75rem"}>
              <Box
                fontSize={"18px"}
                fontWeight={600}
                pb={"20px"}
              >
                Op zoek naar een koopwoning?
              </Box>
              <Box>
                {homeForSale.map((data, i) => {
                  return (
                    <Box>
                      <Box display={"flex"} alignitems={"center"}>
                        <Box>{data.text}</Box>
                      </Box>
                      <FaChevronRight className="chevronnnn" />
                    </Box>
                  );
                })}
              </Box>
            </CadastralData>
          </Box>
        </Box>
      </PricePanel>
    </StyledContainer>
  );
};

const CadastralData = styled(Box)`
  > div > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background-color: #eceff1;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    :hover {
      > div > div {
        text-decoration: underline;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #607d8b;
  text-decoration: none;
  :hover {
    color: #607d8b;
    text-decoration: underline;
  }
`;

const PricePanel = styled(Box)`
  background-color: #ffffff;
  padding: 3.125rem 0;
  display: flex;
  > div {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-left: 0%;
    padding-right: 0%;
  }
`;

const FastPanel = styled(Box)`
  background-color: #ffffff;
  padding: 2.25rem 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  > div {
    max-width: 1200px;
    padding-left: 0%;
    padding-right: 0%;
    margin: 0 auto;

    > div:nth-child(2) > div {
      color: #607d8b;
      padding: 0 1.25rem;
      border-left: 2px solid #607d8b;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
    > div:nth-child(2) > div:first-child {
      border: none;
      padding-left: 0;
    }
  }
`;

const LinkPanel = styled(Box)`
background-color: #607d8b;
  padding: 0 1rem;
  > div {
    max-width: 1200px;
    width: auto;
    margin: 0 auto;
    display: flex;
    min-height: 3.75rem;
    > div {
      display: flex;
      align-items: center;
      > a {
        cursor: pointer;
        color: hsla(0, 0%, 100%, 0.8) !important;
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
      > svg {
        fill: hsla(0, 0%, 100%, 0.8) !important;
        margin: 0 .3rem;
      }
    }
    > div:last-child {
      > svg {
      }
      > a {
        color: white;
        text-decoration: none !important;
      }
      font-weight: 600;
    }
  }
`;
const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export default PostSummary;
