/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Oval } from "react-loader-spinner";
import { ChevronRightSharp } from "@material-ui/icons";

const SearchTable = ({ result, setUrl, pending }) => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [criteria, setCriteria] = useState("");
  const [curPage, setCurPage] = useState(1);
  const [active, setActive] = useState(0);

  const provinces = [
    "Drenthe",
    "Flevoland",
    "Friesland",
    "Gelderland",
    "Groningen",
    "Limburg",
    "Noord-Brabant",
    "Noord-Holland",
    "Overijssel",
    "Utrecht",
    "Zeeland",
    "Zuid-Holland",
  ];

  const getKeys = () => {
    const alphabets = "abcdefghijklmnopqrstuvwxyz";
    const list = alphabets.split("");
    let temp = ["Alles"];
    for (let i = 0; i < list.length; i++) temp.push(list[i].toUpperCase());
    return temp;
  };

  useEffect(() => {
    if (!result) return;
    const cheerio = require("cheerio");
    let $ = cheerio.load(result.html);
    let temp = [],
      _page = 1;
    $("div.table-view__row").each((i, element) => {
      const url = element.children[1].children[1].attribs.href;
      const link = element.children[1].children[1].children[1].data;
      const name = element.children[3].children[0].data;
      temp.push({
        link,
        name,
        url: url.replace("https://www.kadasterdata.nl/", ""),
      });
    });
    $ = cheerio.load(result.paginator);
    $("a.pagination__list-link").each((i, element) => {
      _page = element.children[0].data;
    });
    setPageCount(_page);
    setData(temp);
  }, [result]);

  const handlePageClick = (event) => {
    setCurPage(event.selected + 1);
    // setItemOffset(newOffset);
  };

  useEffect(() => {
    setUrl(
      `https://www.kadasterdata.nl/api-hd/places?p=${curPage}&keyword=${criteria}&sortBy=title&sortDir=asc`
    );
  }, [curPage]);

  return (
    <StyledContainer>
      <div className="jsks92nnnnnn">
      <div className="ismzs-2sidskjs">
      <p className="home-title mobile-bold left-txt"><strong className="bold-text-10">Woningmarkten per provincie</strong><span className="text-span">.</span></p>
      <Provinces>
        
        {provinces.map((data, i) => {
          return (
            <Link
              to={`/provincies/${data.toLowerCase()}`}
              style={{ whiteSpace: "nowrap", textDecoration: "none" }}
            >
              <Button
                type={"primary"}
                width={"fit-content"}
                height={"fit-content"}
                key={i}
              >
                {data}
              </Button>
            </Link>
          );
        })}
      </Provinces>
      </div>
      <div className="ismzs-2sidskjs">
      <p className="home-title mobile-bold left-txt"><strong>Plaatsen van Nederland</strong><span className="text-span">.</span></p>
      <Provinces>
        {getKeys().map((data, i) => {
          return (
            <Link
              to={"#"}
              onClick={() => {
                setUrl(
                  `https://www.kadasterdata.nl/api-hd/places?p=${curPage}&letter=${
                    data === "Alles" ? "" : data
                  }&sortBy=title&sortDir=asc`
                );
                setActive(i);
              }}
              style={{ whiteSpace: "nowrap", textDecoration: "none" }}
            >
              <Button
                type={"primary"}
                width={"fit-content"}
                height={"fit-content"}
                key={i}
                active={active === i}
              >
                {data}
              </Button>
            </Link>
          );
        })}
      </Provinces>
      </div>
      </div>
      <SearchField my={"10px"}>
        <Box mr={"10px"} fontWeight={500}>
          Zoek plaats
        </Box>
        <input
          type={"text"}
          placeholder={"Bijv. Amsterdam"}
          value={criteria}
          onChange={(e) => setCriteria(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setUrl(
                `https://www.kadasterdata.nl/api-hd/places?p=${curPage}&keyword=${criteria}&sortBy=title&sortDir=asc`
              );
            }
          }}
        />
      </SearchField>

      <TableField className="overflow-hidden-27474scroll">
        <TableItem border={"none"} pt={"0"} fontSize={"18px"}>
          <Box>Woonplaats</Box>
          <Box>Gemeente</Box>
          <Box />
        </TableItem>
        <Divider />
        {pending ? (
          <Box className="loader-22202" display={"flex"} justifycontent={"center"} my={"50px"}>
            <Oval
              height={80}
              width={80}
              MarginLeft="auto"
              color="#4fa94d"
              wrapperstyle={{}}
              wrapperclassname=""
              visible={true}
              aria-label="oval-loading"
              secondarycolor="#4fa94d"
              strokeWidth={2}
              strokewidthsecondary={2}
            />
          </Box>
        ) : (
          <Box className="jsjsjsjsjllllllll1">
            {data.map((data, i) => {
              return (
                <TableItem key={i}>
                  <Link to={`/${data.url}`}>{data.link}</Link>
                  <Box fontWeight={500}>{data.name}</Box>
                  <Link to={`/${data.url}`}>Bekijk meer <ChevronRightSharp /></Link>
                  
                </TableItem>
              );
            })}
          </Box>
        )}
      </TableField>
      <Box display={"flex"} justifycontent={"center"} mt={"30px"}>
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active"
        />
      </Box>
    </StyledContainer>
  );
};

const Divider = styled(Box)``;

const TableItem = styled(Box)`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
background-color: #eceff1db;
padding: 0.5rem 1rem;
border-radius: 0.5rem;
border-color: transparent;
margin-top: 0.5rem;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
-ms-flex-pack: justify;
justify-content: space-between;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
border-bottom: 0px;
font-weight: 600;
  > div {
    flex: 1;
  }
  > a {
    flex: 1;
    color: #607d8b;
    :hover {
      text-decoration: underline;
    }
    text-decoration: none;
  }
  > a:nth-child(3) {
    text-align: center;
  }
`;

const TableField = styled(Box)`

`;

const SearchField = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > input {
    background: #fff;
    border: 2px solid #dcdbd5;
    border-radius: 5px;
    color: #263238;
    min-height: 3rem;
    line-height: normal;
    padding: 0.375rem 0.75rem;
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 200px;
  }
`;

const Provinces = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
`;

const StyledContainer = styled(Box)`
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
  margin: 0 auto;
`;

export default SearchTable;
