/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { getSVG } from "../../utils/svgs";


const SolidList = ({ result, detail }) => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false);
  async function fetchData() {
    setPending(true);
    console.log(detail[1].trim().replace(/\n/g, ""));

    try {
      const cheerio = require("cheerio");
      let _result = await axios.post(
        "https://kadaster-backend.vercel.app/api/htmlController/getHTML",
        {
          url: `https://www.kadasterdata.nl/huizendata?keyword=${detail[1]}&sortBy=title&sortDir=asc`,
        }
      );
      let $ = cheerio.load(_result.data.result);
      let key;
      $("div.table-view__row").each((i, element) => {
        const name = element.children[1].children[1].children[1].data;
        if (name.replace(" ", "") === detail[1].trim().replace(/\n/g, ""))
          key = element.children[3].children[0].data;
      });

      let url = `https://www.kadasterdata.nl/api-hd/transactions/street/${detail[2].trim()}-${detail[1].trim()}-${key.trim()}?tSortBy=sale_date&tSortDir=desc&title=Eerder`;
      url = url.replace(/\n/g, "").replace(" ", "%20");
      console.log(url);
      _result = await axios.post(
        "https://kadaster-backend.vercel.app/api/htmlController/getHTML",
        {
          url,
        }
      );
      $ = cheerio.load(_result.data.result.html);
      let temp = [];
      $("tr").each((i, element) => {
        if (i === 0) return;
        const name = element.children[1].children[0].data;
        const no = element.children[3].children[0].data;
        const space = element.children[5].children[0].data;
        const plot = element.children[7].children[0].data;
        const date = element.children[9].children[0].data;
        temp.push({ name, no, space, plot, date });
      });
      setData(temp);
    } catch (error) {
      console.log(error);
    }
    setPending(false);
  }
  useEffect(() => {
    if (!detail) return;
    fetchData();
  }, [detail]);
  return (
    <StyledContainer>
      <Box>
        <Box className="jshf47hfnf"
          display={"flex"}
          justifycontent={"space-between"}
          alignitems={"center"}
          mb={"10px"}
        >
          <Box className="jshf47hfnf" fontSize={"22px"} fontWeight={600}>
            Eerder verkochte woningen in de buurt
          </Box>
          <StyledLink to={"#"}>Andere koopsommen opvragen</StyledLink>
        </Box>
        <TableHeader>
          <Box width={"160px"}>Straatnaam</Box>
          <Box width={"80px"}>Huisnr.</Box>
          <Box width={"150px"}>Woonoppervlak</Box>
          <Box width={"80px"}>Perceel</Box>
          <Box width={"250px"}>Verkoopdatum</Box>
          <Box width={"200px"}>Verkoopprijs </Box>
        </TableHeader>
        {pending ? (
          <Box className="loader-22202" display={"flex"} justifycontent={"center"} my={"50px"}>
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              MarginLeft="auto"
              wrapperstyle={{}}
              wrapperclassname=""
              visible={true}
              aria-label="oval-loading"
              secondarycolor="#4fa94d"
              strokeWidth={2}
              strokewidthsecondary={2}
            />
          </Box>
        ) : (
          <TableBody>
            {data.map((data, i) => {
              return (
                <Box key={i}>
                  <Box width={"160px"} to={`/${data.url}`}>{data.name}</Box>
                  <Box width={"80px"}>{data.no}</Box>
                  <Box width={"150px"}>{data.space}</Box>
                  <Box width={"80px"}>{data.plot}</Box>
                  <Box width={"250px"}>{data.date}</Box>
                  <StyledLink to={"#"} style={{ width: "200px" }}>
                    Koopsom opvragen
                  </StyledLink>
                </Box>
              );
            })}
          </TableBody>
        )}
      </Box>
    </StyledContainer>
  );
};

const TableHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  > div {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 0;
  }
  > div:last-child {
    text-align: right;
  }
  border-bottom: 2px solid #dcdbd5;
  background-color: #eceff1db;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border-color: transparent;
`;

const TableBody = styled(Box)`
  > div {
    display: flex;
    background-color: #eceff1db;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border-color: transparent;
    margin-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
    > div {
      padding: 0;
    }
    > a {
      display: flex;
      justify-content: flex-end;
    }
    border-bottom: 0px;
  }
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #607d8b;
  text-decoration: none;
  :hover {
    color: #607d8b;
    text-decoration: underline;
  }
  height: auto;
  display: flex;
  align-items: center;
`;

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;
  > div {
    max-width: 1200px;
    margin: 0 auto;
    background-color: transparent;
    border: 0px;
    border-radius: 5px;
    padding: 0;
    height: fit-content;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
  }
  background-color: #fff;
`;

export default SolidList;
