/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import { useState, useEffect } from "react";

const MarketValue = ({ result }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (!result) return;
    try {
      const cheerio = require("cheerio");
      let $ = cheerio.load(result);
      let marketvalue;
      $("div.properties").each((i, element) => {
        if (i === 0) marketvalue = $(element).html();
      });
      setData({ marketvalue });
    } catch (error) {
      console.log(error);
    }
  }, [result]);
  return (
    <StyledContainer>
      <Box fontSize={"22px"} fontWeight={600}>
        Woningmarkt en woningwaarde
      </Box>
      <Box dangerouslySetInnerHTML={{ __html: data?.marketvalue }} />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;

  background-color: #fff;
  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 0%;
    padding-right: 0%;
  }
  .container__row {
    display: flex;
    > div {
      margin-top: 1rem;
      padding: 0 0.5rem;
      > div {
        border: 2px solid #ddd;
        border-radius: 5px;
        padding: 2rem;
        width: 280px;
      }
    }
  }
  .container__row > div:first-child {
    padding-left: 0;
  }
  .container__row > div:last-child {
    padding-right: 0;
  }
  .property-card__title {
    margin: 1rem 0 0.5rem;
    font-size: 1.375rem;
    font-weight: 600;
  }
  .property-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export default MarketValue;
