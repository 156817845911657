/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import HeroSection from "./HeroSection";
import SearchTable from "./SearchTable";
import Populate from "./Populate";
import { useEffect, useState } from "react";
import axios from "axios";
import Paper from "@mui/material";

const Home = () => {
  const [result, setResult] = useState("");
  const [url, setUrl] = useState("https://www.kadasterdata.nl/api-hd/places");
  const [pending, setPending] = useState(false);
  async function fetchData() {
    setPending(true);
    try {
      const _result = await axios.post(
        "https://kadaster-backend.vercel.app/api/htmlController/getHTML",
        { url }
      );
      setResult(_result.data.result);
    } catch (error) {
      console.log(error);
    }
    setPending(false);
  }

  useEffect(() => {
    if (!url.length) return;
    fetchData();
  }, [url]);
  return (
    <StyledContainer>
      <HeroSection />
      <Populate result={result} />
      <SearchTable result={result} setUrl={setUrl} pending={pending} />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;
`;

export default Home;
