/* eslint-disable jsx-a11y/alt-text */
import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getSVG } from "../../utils/svgs";


const HomeCards = ({ result, detail }) => {
  const [homeData, setHomeData] = useState([]);
  const [recentData, setRecentData] = useState([]);

  useEffect(() => {
    if (!result) return;
    const cheerio = require("cheerio");
    let $ = cheerio.load(result);
    let _homeData = [],
      _recentData = [];
    $("a.sales-card").each((i, element) => {
      if (element.parent.attribs.class === "container__col")
        _homeData.push({
          svg: getSVG($(element.children[1].children[1]).html()),
          text: $(element.children[3]).html(),
          link: element.attribs.href.replace("https://www.kadasterdata.nl", ""),
        });
      else
        _recentData.push({
          svg: getSVG($(element.children[1].children[1]).html()),
          text: $(element.children[3]).html(),
          link: element.attribs.href.replace("https://www.kadasterdata.nl", ""),
        });
    });
    setHomeData(_homeData);
    setRecentData(_recentData);
  }, [result]);
  return (
    <StyledContainer>
      <Box>
        <Box>
          <Box
            display={"flex"}
            className="jshf47hfnf"
            justifycontent={"space-between"}
          >
            <Box className="jshf47hfnf" fontSize={"22px"} fontWeight={600}>
              Woningen te koop in {detail ? detail[2] : ""}
            </Box>
            <Button className="primeBTN" to={"#"} variant="outlined" style={{ color: "#607d8b", borderColor: "#607d8b", height: "35px" }}>Bekijk meer aanbod</Button>
          </Box>
          <div className="hshhhshshhshshs1111 mobielslsifisfs-ss43s">
            {homeData.map((data, i) => {
              return (
                <Link className="hsh474hdkdkdkdk" to={data.link} key={i}>
                  <Box className="lisnks2oskfkjs2">
                    <Box width={"100px"} height={"100px"} display={"flex"}>
                      {data.svg}
                    </Box>
                    <Box
                      key={i}
                      dangerouslySetInnerHTML={{ __html: data.text }}
                    />
                  </Box>
                </Link>
              );
            })}
          </div>
        </Box>
        <Box mt={"80px"}>
          <Box
          className="shf833"
            display={"flex"}
            justifycontent={"space-between"}
          >
            <Box fontSize={"22px"} fontWeight={600}>
              Recent verkocht in {detail ? detail[3] : ""}
            </Box>
      <Button className="primeBTN" to={"#"} variant="outlined" style={{ color: "#607d8b", borderColor: "#607d8b", height: "35px" }}>Bekijk alle verkochte woningen</Button>
          </Box>
          <div className="hshhhshshhshshs1111 mobielslsifisfs-ss43s">
            {recentData.map((data, i) => {
              return (
                <Link className="hsh474hdkdkdkdk" to={data.link} key={i}>
                  <Box className="lisnks2oskfkjs2">
                    <Box width={"100px"} height={"100px"} display={"flex"}>
                      {data.svg}
                    </Box>
                    <Box
                      key={i}
                      dangerouslySetInnerHTML={{ __html: data.text }}
                    />
                  </Box>
                </Link>
              );
            })}
          </div>
        </Box>
      </Box>
    </StyledContainer>
  );
};

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #607d8b;
  text-decoration: none;
  :hover {
    color: #607d8b;
    text-decoration: underline;
  }
`;

const HomeData = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > a {
    padding: 0 0.5rem;
    margin-top: 1rem;
    text-decoration: none;
    > div {
      background: #eceff1;
      border-radius: 5px;
      border: 0.125rem solid #dcdbd5;
      color: #263238;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      width: 280px;
    }
  }

  .sales-card__content {
    padding: 0 1.5rem 1rem;
  }
  .sales-card__title {
    margin: 1rem 0 0.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    height: fit-content;
  }
  .sales-card__button {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    width: 100%;
    > svg {
      transition: all 0.5s;
      transform: scale(1.4);
    }
    :hover {
      > svg {
        margin-right: -5px;
      }
    }
  }
  .sales-card__image-wrapper {
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;
  background: white;
  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 0%;
    padding-right: 0%;
  }
`;
export default HomeCards;
