/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import PostSummary from "./PostSummary";
import MarketValue from "./MarketValue";
import HomeCards from "./HomeCards";
import SearchTable from "./SearchTable";
import BouncingDotsLoader from "../../components/BouncingDotsLoader";


const Provincies = () => {
  const hrefList = window.location.href.split("/");
  const href =
    hrefList[hrefList.length - 2] + "/" + hrefList[hrefList.length - 1];
  const [url, setUrl] = useState(`https://www.kadasterdata.nl/${href}`);
  const [result, setResult] = useState(null);
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);

  async function fetchData() {
    setPending(true);
    try {
      const _result = await axios.post(
        "https://kadaster-backend.vercel.app/api/htmlController/getHTML",
        { url }
      );
      setResult(_result.data.result);
      const cheerio = require("cheerio");
      let $ = cheerio.load(_result.data.result);
      let links = [],
        title,
        hrefs = [];
      $("li.breadcrumb__item").each((i, element) => {
        links.push(element.children[1].children[0].data);
        hrefs.push(
          i !== 1
            ? element.children[1].attribs.href.replace(
                "https://www.kadasterdata.nl",
                ""
              )
            : ""
        );
      });
      $("h1.page-header__title").each((i, element) => {
        title = element.children[0].data.toUpperCase();
      });
      setData({ links, title, hrefs });
    } catch (error) {
      console.log(error);
    }
    setPending(false);
  }
  useEffect(() => {
    fetchData();
  }, [url]);
  return (
    <StyledContainer width={"100%"}>
      {!data ? (
                 <BouncingDotsLoader />

      ) : (
        <>
          <LinkPanel>
            <Box>
              {data &&
                data.links.map((text, i) => {
                  return (
                    <Box>
                      <Box as={Link} to={data.hrefs[i]}>
                        {text}
                      </Box>
                      <FaChevronRight className="chevronnnn"/>
                    </Box>
                  );
                })}
            </Box>
          </LinkPanel>
          <Box bgcolor={"#eceff1"}>
            <Box maxWidth={"1200px"} mx={"auto"} className="topName">
              <Box
                color={"#263238"}
                fontWeight={"600"}
                fontSize={"1.5rem"}
              >
                {data?.title}
              </Box>
            </Box>
          </Box>

          <PostSummary result={result} detail={data?.links} />
          <MarketValue result={result} />
          <HomeCards result={result} detail={data?.links} />
          <SearchTable href={href.replace("provincies", "province")} />
        </>
      )}
    </StyledContainer>
  );
};

const LinkPanel = styled(Box)`
background-color: #607d8b;
  padding: 0 1rem;
  > div {
    max-width: 1200px;
    width: auto;
    margin: 0 auto;
    display: flex;
    min-height: 3.75rem;
    > div {
      display: flex;
      align-items: center;
      > a {
        cursor: pointer;
        color: hsla(0, 0%, 100%, 0.8) !important;
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
      > svg {
        fill: hsla(0, 0%, 100%, 0.8) !important;
        margin: 0 .3rem;
      }
    }
    > div:last-child {
      > svg {
      }
      > a {
        color: white;
        text-decoration: none !important;
      }
      font-weight: 600;
    }
  }
`;

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 70px;
`;

export default Provincies;
