/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { getSVG } from "../../utils/svgs";
 

 


const HomeCards = ({ result, detail, setUrl, pending, name }) => {
  const [homeData, setHomeData] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  

  useEffect(() => {
    if (!result) return;
    const cheerio = require("cheerio");
    let $ = cheerio.load(result);
    let _homeData = [];
    $("a.sales-card").each((i, element) => {
      if (element.parent.attribs.class === "container__col") {
        _homeData.push({
          getSVG: getSVG,
          svg: $(element.children[1].children[1]).html(),
          text: $(element.children[3]).html(),
          link: element.attribs.href.replace("https://www.kadasterdata.nl", ""),
        });
      }
    });
    let _pageCount;
    $("a.pagination__list-link").each((i, element) => {
      console.log(element);
      _pageCount = element.children[0].data;
    });
    setPageCount(_pageCount);
    setHomeData(_homeData);
  }, [result]);

  const handlePageClick = (event) => {
    setCurPage(event.selected + 1);
    // setItemOffset(newOffset);
  };
  

  useEffect(() => {
    setUrl(`https://www.kadasterdata.nl/koopwoningen/${name}?p=${curPage}`);
  }, [curPage]);

  return (
    <StyledContainer>
      <div className="container-222-2">

        <Box>
          
          <div
            className="snhhhhhh33333-2"
            display={"flex"}
            justifycontent={"space-between"}
            alignitems={"center"}
          >
            <div className="iiikekkej222" fontSize={"22px"} fontWeight={600}>
              Woningen te koop {detail ? detail[2] : ""}
            </div>
            <Button className="primeBTN" to={"#"} variant="outlined" style={{ color: "#607d8b", borderColor: "#607d8b", height: "35px" }}>Bekijk meer aanbod</Button>
          </div>
          {!pending ? (
            <div className="hshhhshshhshshs1111 mobielslsifisfs-ss43s">
    
              {homeData.map((data, i) => {
                return (
                  <Link className="hsh474hdkdkdkdk" to={data.link} key={i}>
                  <Box className="lisnks2oskfkjs2">
                    <Box className="sales-card__image-wrapper" height={"100px"} display={"flex"}>
                    {data.getSVG(data.svg)}
                    </Box>
                    <Box className="sales-card__content hhhhhjjjejejejejjejej"
                      key={i}
                      dangerouslySetInnerHTML={{ __html: data.text }}
                    />
                  </Box >
                </Link>
                );
              })}
            </div>
          ) : (
            <div
              className="sdksk2k2k222"
              display={"flex"}
              justifycontent={"center"}
              my={"50px"}
            >
              <div
                className="iiii28888"
                height={80}
                width={80}
                color="#ffffff"
                wrapperstyle={{}}
                wrapperclassname=""
                visible={true}
                aria-label="oval-loading"
                secondarycolor="#ffffff"
                strokeWidth={2}
                strokewidthsecondary={2}
              />
            </div>
          )}
          <div
            className="jjjjjj"
            display={"flex"}
            justifycontent={"center"}
            mt={"30px"}
          >
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </Box>
      </div>
    </StyledContainer>
  );
};

const StyledLink = styled(Link)``;

const HomeData = styled(Box)``;

const StyledContainer = styled(Box)``;

export default HomeCards;
