export  const carts ={
    "cartItems": [
        {
            "key": "a3f390d88e4c41f2747bfa2f1b5f87db",
            "product_id": 68,
            "variation_id": 0,
            "variation": [],
            "quantity": 1,
            "data_hash": "b5c1d5ca8bae6d4896cf1807cdf763f0",
            "line_tax_data": {
                "subtotal": [],
                "total": []
            },
            "line_subtotal": 18,
            "line_subtotal_tax": 0,
            "line_total": 18,
            "line_tax": 0,
            "data": {
                "id": 68,
                "name": "Health Products",
                "slug": "t-shirt-with-logo",
                "date_created": {
                    "date": "2021-08-21 08:28:42.000000",
                    "timezone_type": 1,
                    "timezone": "+00:00"
                },
                "date_modified": {
                    "date": "2021-09-04 08:37:28.000000",
                    "timezone_type": 1,
                    "timezone": "+00:00"
                },
                "status": "publish",
                "featured": false,
                "catalog_visibility": "visible",
                "description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
                "short_description": "This is a simple product.",
                "sku": "Woo-tshirt-logo",
                "price": "18",
                "regular_price": "18",
                "sale_price": "",
                "date_on_sale_from": null,
                "date_on_sale_to": null,
                "total_sales": 0,
                "tax_status": "taxable",
                "tax_class": "",
                "manage_stock": false,
                "stock_quantity": null,
                "stock_status": "instock",
                "backorders": "no",
                "low_stock_amount": "",
                "sold_individually": false,
                "weight": "",
                "length": "",
                "width": "",
                "height": "",
                "upsell_ids": [],
                "cross_sell_ids": [],
                "parent_id": 0,
                "reviews_allowed": true,
                "purchase_note": "",
                "attributes": {
                    "pa_color": {}
                },
                "default_attributes": [],
                "menu_order": 0,
                "post_password": "",
                "virtual": false,
                "downloadable": false,
                "category_ids": [
                    36
                ],
                "tag_ids": [],
                "shipping_class_id": 0,
                "downloads": [],
                "image_id": "130",
                "gallery_image_ids": [],
                "download_limit": 0,
                "download_expiry": 0,
                "rating_counts": [],
                "average_rating": "0",
                "review_count": 0,
                "meta_data": [
                    {
                        "id": 662,
                        "key": "_wpcom_is_markdown",
                        "value": "1"
                    }
                ],
                "images": [
                    {
                        "id": 130,
                        "src": "https://mydemo.codeytek.com/wp-content/uploads/2021/08/tyler-nix-443x_HqPtgY-unsplash.jpg",
                        "name": "tyler-nix-443x_HqPtgY-unsplash",
                        "alt": ""
                    }
                ]
            },
            "currency": "$"
        },
        {
            "key": "072b030ba126b2f4b2374f342be9ed44",
            "product_id": 60,
            "variation_id": 0,
            "variation": [],
            "quantity": 1,
            "data_hash": "b5c1d5ca8bae6d4896cf1807cdf763f0",
            "line_tax_data": {
                "subtotal": [],
                "total": []
            },
            "line_subtotal": 15,
            "line_subtotal_tax": 0,
            "line_total": 15,
            "line_tax": 0,
            "data": {
                "id": 60,
                "name": "Febreeze Air",
                "slug": "febreeze-air",
                "date_created": {
                    "date": "2021-08-21 08:28:42.000000",
                    "timezone_type": 1,
                    "timezone": "+00:00"
                },
                "date_modified": {
                    "date": "2021-09-04 08:38:07.000000",
                    "timezone_type": 1,
                    "timezone": "+00:00"
                },
                "status": "publish",
                "featured": false,
                "catalog_visibility": "visible",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sagittis orci ac odio dictum tincidunt. Donec ut metus leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed luctus, dui eu sagittis sodales, nulla nibh sagittis augue, vel porttitor diam enim non metus. Vestibulum aliquam augue neque. Phasellus tincidunt odio eget ullamcorper efficitur. Cras placerat ut turpis pellentesque vulputate. Nam sed consequat tortor. Curabitur finibus sapien dolor. Ut eleifend tellus nec erat pulvinar dignissim. Nam non arcu purus. Vivamus et massa massa.",
                "short_description": "This is a simple, virtual product.",
                "sku": "woo-album",
                "price": "15",
                "regular_price": "15",
                "sale_price": "",
                "date_on_sale_from": null,
                "date_on_sale_to": null,
                "total_sales": 0,
                "tax_status": "taxable",
                "tax_class": "",
                "manage_stock": false,
                "stock_quantity": null,
                "stock_status": "instock",
                "backorders": "no",
                "low_stock_amount": "",
                "sold_individually": false,
                "weight": "",
                "length": "",
                "width": "",
                "height": "",
                "upsell_ids": [],
                "cross_sell_ids": [],
                "parent_id": 0,
                "reviews_allowed": true,
                "purchase_note": "",
                "attributes": [],
                "default_attributes": [],
                "menu_order": 0,
                "post_password": "",
                "virtual": true,
                "downloadable": true,
                "category_ids": [
                    36,
                    22
                ],
                "tag_ids": [],
                "shipping_class_id": 0,
                "downloads": {
                    "cdcbe6f4-2a12-4d2c-bef5-018f3c0caa6d": {},
                    "e4d9e03a-9e6b-47ea-be4f-fd641bd21585": {}
                },
                "image_id": "126",
                "gallery_image_ids": [],
                "download_limit": 1,
                "download_expiry": 1,
                "rating_counts": [],
                "average_rating": "0",
                "review_count": 0,
                "meta_data": [
                    {
                        "id": 594,
                        "key": "_wpcom_is_markdown",
                        "value": "1"
                    }
                ],
                "images": [
                    {
                        "id": 126,
                        "src": "https://mydemo.codeytek.com/wp-content/uploads/2021/08/giorgio-trovato-7bw3REiKLI0-unsplash-1.jpg",
                        "name": "giorgio-trovato-7bw3REiKLI0-unsplash (1)",
                        "alt": ""
                    }
                ]
            },
            "currency": "$"
        },
        {
            "key": "093f65e080a295f8076b1c5722a46aa2",
            "product_id": 59,
            "variation_id": 0,
            "variation": [],
            "quantity": 1,
            "data_hash": "b5c1d5ca8bae6d4896cf1807cdf763f0",
            "line_tax_data": {
                "subtotal": [],
                "total": []
            },
            "line_subtotal": 20,
            "line_subtotal_tax": 0,
            "line_total": 20,
            "line_tax": 0,
            "data": {
                "id": 59,
                "name": "Coffee Beans",
                "slug": "coffee-beans",
                "date_created": {
                    "date": "2021-08-21 08:28:42.000000",
                    "timezone_type": 1,
                    "timezone": "+00:00"
                },
                "date_modified": {
                    "date": "2021-09-04 08:05:51.000000",
                    "timezone_type": 1,
                    "timezone": "+00:00"
                },
                "status": "publish",
                "featured": false,
                "catalog_visibility": "visible",
                "description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
                "short_description": "This is a simple product.",
                "sku": "woo-polo",
                "price": "20",
                "regular_price": "20",
                "sale_price": "",
                "date_on_sale_from": null,
                "date_on_sale_to": null,
                "total_sales": 0,
                "tax_status": "taxable",
                "tax_class": "",
                "manage_stock": false,
                "stock_quantity": null,
                "stock_status": "instock",
                "backorders": "no",
                "low_stock_amount": "",
                "sold_individually": false,
                "weight": "",
                "length": "",
                "width": "",
                "height": "",
                "upsell_ids": [],
                "cross_sell_ids": [],
                "parent_id": 0,
                "reviews_allowed": true,
                "purchase_note": "",
                "attributes": {
                    "pa_color": {}
                },
                "default_attributes": [],
                "menu_order": 0,
                "post_password": "",
                "virtual": false,
                "downloadable": false,
                "category_ids": [
                    19
                ],
                "tag_ids": [],
                "shipping_class_id": 0,
                "downloads": [],
                "image_id": "129",
                "gallery_image_ids": [],
                "download_limit": 0,
                "download_expiry": 0,
                "rating_counts": [],
                "average_rating": "0",
                "review_count": 0,
                "meta_data": [
                    {
                        "id": 585,
                        "key": "_wpcom_is_markdown",
                        "value": "1"
                    }
                ],
                "images": [
                    {
                        "id": 129,
                        "src": "https://mydemo.codeytek.com/wp-content/uploads/2021/08/tamas-pap-FNOYT3NDdE0-unsplash.jpg",
                        "name": "tamas-pap-FNOYT3NDdE0-unsplash",
                        "alt": ""
                    }
                ]
            },
            "currency": "$"
        },
        {
            "key": "66f041e16a60928b05a7e228a89c3799",
            "product_id": 58,
            "variation_id": 0,
            "variation": [],
            "quantity": 1,
            "data_hash": "b5c1d5ca8bae6d4896cf1807cdf763f0",
            "line_tax_data": {
                "subtotal": [],
                "total": []
            },
            "line_subtotal": 25,
            "line_subtotal_tax": 0,
            "line_total": 25,
            "line_tax": 0,
            "data": {
                "id": 58,
                "name": "Graphic Designs",
                "slug": "graphic-designs",
                "date_created": {
                    "date": "2021-08-21 08:28:42.000000",
                    "timezone_type": 1,
                    "timezone": "+00:00"
                },
                "date_modified": {
                    "date": "2021-09-04 08:07:47.000000",
                    "timezone_type": 1,
                    "timezone": "+00:00"
                },
                "status": "publish",
                "featured": false,
                "catalog_visibility": "visible",
                "description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
                "short_description": "This is a simple product.",
                "sku": "woo-long-sleeve-tee",
                "price": "25",
                "regular_price": "25",
                "sale_price": "",
                "date_on_sale_from": null,
                "date_on_sale_to": null,
                "total_sales": 0,
                "tax_status": "taxable",
                "tax_class": "",
                "manage_stock": false,
                "stock_quantity": null,
                "stock_status": "instock",
                "backorders": "no",
                "low_stock_amount": "",
                "sold_individually": false,
                "weight": "",
                "length": "",
                "width": "",
                "height": "",
                "upsell_ids": [],
                "cross_sell_ids": [],
                "parent_id": 0,
                "reviews_allowed": true,
                "purchase_note": "",
                "attributes": {
                    "pa_color": {}
                },
                "default_attributes": [],
                "menu_order": 0,
                "post_password": "",
                "virtual": false,
                "downloadable": false,
                "category_ids": [
                    19
                ],
                "tag_ids": [],
                "shipping_class_id": 0,
                "downloads": [],
                "image_id": "131",
                "gallery_image_ids": [],
                "download_limit": 0,
                "download_expiry": 0,
                "rating_counts": [],
                "average_rating": "0",
                "review_count": 0,
                "meta_data": [
                    {
                        "id": 576,
                        "key": "_wpcom_is_markdown",
                        "value": "1"
                    }
                ],
                "images": [
                    {
                        "id": 131,
                        "src": "https://mydemo.codeytek.com/wp-content/uploads/2021/08/giorgio-trovato-sdUyh4rtITo-unsplash.jpg",
                        "name": "giorgio-trovato-sdUyh4rtITo-unsplash",
                        "alt": ""
                    }
                ]
            },
            "currency": "$"
        }
    ],
    "totalQty": 4,
    "totalPrice": 78
}