import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import SliderComponent from './SliderProduct';
import { useNavigate, useParams } from 'react-router-dom';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ProductsGrid() {
  return (
 
    <Box sx={{ flexGrow: 1 }} style={{ marginTop: '2rem' }}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={4}>
          <Item component="a" href="https://kadasterzoom.nl/?add-to-cart=231348" style={{ width: '100%', display: 'block', border: '1px solid #ddd', boxShadow: 'none', padding: '1rem', textAlign: 'left' }}>
            <Box className='flexBox--2'>
           <Box> Eigendomsinformatie </Box>
           <Button> Bekijk product </Button>
            </Box>
            </Item>
        </Grid>
        <Grid xs={12} sm={4}>
          <Item component="a" href="/product" style={{ width: '100%', display: 'block', border: '1px solid #ddd', boxShadow: 'none', padding: '1rem', textAlign: 'left' }}>
            <Box className='flexBox--2'>
           <Box> Koopsominformatie </Box>
           <Button> Bekijk product </Button>
            </Box>
            </Item>
        </Grid>
        <Grid xs={12} sm={4}>
          <Item component="a" href="/product" style={{ width: '100%', display: 'block', border: '1px solid #ddd', boxShadow: 'none', padding: '1rem', textAlign: 'left' }}>
            <Box className='flexBox--2'>
           <Box> Hypotheekinformatie </Box>
           <Button> Bekijk product </Button>
            </Box>
            </Item>
        </Grid>
        <Grid xs={12} sm={4}>
          <Item component="a" href="/product" style={{ width: '100%', display: 'block', border: '1px solid #ddd', boxShadow: 'none', padding: '1rem', textAlign: 'left' }}>
            <Box className='flexBox--2'>
           <Box> Woningwaarde rapport </Box>
           <Button> Bekijk product </Button>
            </Box>
            </Item>
        </Grid>
        <Grid xs={12} sm={4}>
          <Item component="a" href="/product" style={{ width: '100%', display: 'block', border: '1px solid #ddd', boxShadow: 'none', padding: '1rem', textAlign: 'left' }}>
            <Box className='flexBox--2'>
           <Box> Woningwaarde rapport </Box>
           <Button> Bekijk product </Button>
            </Box>
            </Item>
        </Grid>
        <Grid xs={12} sm={4}>
          <Item component="a" href="/product" style={{ width: '100%', display: 'block', border: '1px solid #ddd', boxShadow: 'none', padding: '1rem', textAlign: 'left' }}>
            <Box className='flexBox--2'>
           <Box> Woningwaarde rapport </Box>
           <Button> Bekijk product </Button>
            </Box>
            </Item>
        </Grid>


       
        
      </Grid>
    </Box>
  );
}