import { useNavigate, useParams } from 'react-router-dom';

function KoopsomInformatie() {
  const navigate = useNavigate();

  const hrefList = window.location.href.split("/");
    const href = hrefList[hrefList.length - 3] + "/" + hrefList[hrefList.length - 2] + "+" + hrefList[hrefList.length - 1];;

  const idString = href.replace("/", "+");
  const wordpressUrl = `https://www.kadasterzoom.nl/afrekenen/?add-to-cart=231293&billing_adres=${idString}`;

  const handleClick = () => {
    window.open(wordpressUrl, '_blank');
  };

  return (
    <div>
      <button onClick={handleClick} target="_blank">Woningwaarde rapport
</button>
    </div>
  );
}
export default KoopsomInformatie;