/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components";
import { Box, Link } from "@mui/material";
import Button from "../../components/Button";
import PlaatsenLink from "./PlaatsenLink";
import KadastraleBtn1 from "./KadastraleBtn";
import KadastraleBtn2 from "./KadastraleKaart";


const Topbar = () => {
  return (
    <StyledContainer>
      <Box>

  <Box className="div-block-16">
    <Box className="div-block-17">
      <a href="/" className="paragraph-3">Kadasterzoom<span className="text-span-6">.</span>nl</a>
    </Box>
    <Box className="div-block-18">
      <Box className="div-block-19">
      <KadastraleBtn1 className="link" />
      <PlaatsenLink className="link" />
      <KadastraleBtn2 className="link" />
      </Box>
      <Box className="div-block-19">
        <a href="#" className="link">Registreer</a>
        <Box className="div-block-22">
          <a href="#" className="link">Login</a>
        </Box>
 
        <Button type={"primary"}>Producten</Button>      
        </Box>
    </Box>
  </Box>
      </Box>
    </StyledContainer>
  );
};

export default Topbar;


const StyledContainer = styled(Box)`
  background: white;
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 15%);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99;
  display: flex;
  > div {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
`;
