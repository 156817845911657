import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

export default function PlaatsenLink() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Plaatsen
      </Button>
      <Menu
        id="fade-menu"
        className="snfhy377"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>Amsterdam</MenuItem>
        <MenuItem onClick={handleClose}>Rotterdam</MenuItem>
        <MenuItem onClick={handleClose}>Den Haag</MenuItem>
        <MenuItem onClick={handleClose}>Utrecht</MenuItem>
        <MenuItem onClick={handleClose}>Eindhoven</MenuItem>
        <MenuItem onClick={handleClose}>Groningen</MenuItem>
        <MenuItem onClick={handleClose}>Tilburg</MenuItem>
        <MenuItem onClick={handleClose}>Almere</MenuItem>


      </Menu>
    </div>
  );
}
