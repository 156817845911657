/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import PostSummary from "./PostSummary";
import HomeCards from "./HomeCards";
import Charateristics from "./Charateristics";
import SoldList from "./SoldList";
import BouncingDotsLoader from "../../components/BouncingDotsLoader";
import { getSVG } from "../../utils/svgs";


const Property = () => {
  const hrefList = window.location.href.split("/");
  const href =
    hrefList[hrefList.length - 3] +
    "/" +
    hrefList[hrefList.length - 2] +
    "/" +
    hrefList[hrefList.length - 1];

  const [url, setUrl] = useState(`https://www.kadasterdata.nl/${href}`);
  const [result, setResult] = useState(null);
  const [data, setData] = useState(null);
  

  async function fetchData() {
    try {
      const _result = await axios.post(
        "https://kadaster-backend.vercel.app/api/htmlController/getHTML",
        { url }
      );
      setResult(_result.data.result);
      const cheerio = require("cheerio");
      let $ = cheerio.load(_result.data.result);
      let headerIcon;
      let links = [],
        title,
        hrefs = [];
      $("li.breadcrumb__item").each((i, element) => {
        links.push(element.children[1].children[0].data);
        hrefs.push(
          i !== 3
            ? element.children[1].attribs.href.replace(
                "https://www.kadasterdata.nl",
                ""
              )
            : ""
        );
      });
      $("h1.page-header__title").each((i, element) => {
        title = element.children[0].data;
      });
      $("div.page-header__icon").each((i, element) => {
        headerIcon = $(element).html();
      });

      setData({ headerIcon, links, title, hrefs });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    console.log(url);
    fetchData();
  }, [url]);
  return (
    <StyledContainer>
      {!data ? (
          <BouncingDotsLoader />

      ) : (
        <>

          <LinkPanel>
            <Box>
              {data &&
                data.links.map((text, i) => {
                  return (
                    <Box key={i}>
                      <Box as={Link} to={data.hrefs[i]}>
                        {text}
                      </Box>
                      <FaChevronRight className="chevronnnn" />
                    </Box>
                  );
                })}
            </Box>
          </LinkPanel>
          <Box bgcolor={"#eceff1"}>
            <Box
              maxWidth={"1200px"}
              mx={"auto"}
              className="topName"
              display={"flex"}
              alignitems={"center"}
            >
                      {data.svg}

              <Box
                color={"#263238"}
                fontWeight={"600"}
                fontSize={"1.5rem"}
              >
                {data?.title}
              </Box>
            </Box>
            <PostSummary result={result} detail={data?.links} />
            <Charateristics result={result} />
            <SoldList result={result} detail={data?.links} />

            <HomeCards
              result={result}
              detail={data?.links}
              setUrl={setUrl}
              setData={setData}
            />
          </Box>
        </>
      )}
    </StyledContainer>
  );
};

const HeaderCircle = styled(Box)`
  border: 4px solid #dcdbd5;
  background: #ffffff;
  border-radius: 50%;
  width: fit-content;
`;

const LinkPanel = styled(Box)`
background-color: #607d8b;
  padding: 0 1rem;
  > div {
    max-width: 1200px;
    width: auto;
    margin: 0 auto;
    display: flex;
    min-height: 3.75rem;
    > div {
      display: flex;
      align-items: center;
      > a {
        cursor: pointer;
        color: hsla(0, 0%, 100%, 0.8) !important;
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
      > svg {
        fill: hsla(0, 0%, 100%, 0.8) !important;
        margin: 0 .3rem;
        
      }
    }
    > div:last-child {
      > svg {
       
      }
      > a {
        color: white;
        text-decoration: none !important;
      }
      font-weight: 600;
    }
  }
`;

const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
`;

export default Property;
