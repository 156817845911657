import React, { useState } from 'react';
import Slider from 'react-slick';

function SliderComponent() {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    afterChange: index => setActiveIndex(index)
  };

  const handleSlideButtonClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div>
      <Slider {...settings}>
        <div><h1>Slide 1</h1></div>
        <div><h1>Slide 2</h1></div>
        <div><h1>Slide 3</h1></div>
      </Slider>
      <div>
        <button className={`slider-button ${activeIndex === 0 ? 'active' : ''}`} onClick={() => handleSlideButtonClick(0)} data-slide={0}>Slide 1</button>
        <button className={`slider-button ${activeIndex === 1 ? 'active' : ''}`} onClick={() => handleSlideButtonClick(1)} data-slide={1}>Slide 2</button>
        <button className={`slider-button ${activeIndex === 2 ? 'active' : ''}`} onClick={() => handleSlideButtonClick(2)} data-slide={2}>Slide 3</button>
      </div>
    </div>
  );
}

export default SliderComponent;
